<template>
  <div
    class="z-50 w-[180px] rounded-[50px] border-[6px] border-[#FFC125]/80 bg-gradient-to-t from-[#FFFFFF15] to-[#D9D9D90B] sm:w-[200px] lg:w-[300px]"
    style="
            background: linear-gradient(to top, #ffffff15, #d9d9d90b);
            backdrop-filter: blur(10px);        
            -webkit-backdrop-filter: blur(10px);
          ">
    <div class="relative flex flex-col items-center justify-center p-4">
      <img class="absolute top-0 -mt-[52px] -ml-10 h-[80px] w-auto translate-x-4 lg:-mt-[100px] lg:h-[150px]"
        :src="imageSrc" :alt="imageAlt" />
      <div class="mt-5 flex w-full flex-col items-center justify-center gap-2 sm:gap-4 lg:mt-8 lg:gap-6">
        <p class=" z-50  font bold font-inter text-sm text-white lg:text-xl">
          {{ top3.length > 0 ? maskString(top3[rank - 1].username) : '' }}
        </p>
        <div>
          <p class=" z-50  font-inter text-lg font-bold text-white md:text-3xl lg:text-4xl">
            $ {{ top3.length > 0 ? top3[rank - 1].weightedWagered.toFixed(2) : '' }}
          </p>
          <p class="font-inter text-base uppercase text-white lg:text-lg">WAGER</p>
        </div>
        <div
          class="relative my-1 w-full rounded-[10px] bg-gradient-to-r from-[#FFC125] to-[#FFC125]/20 py-1 lg:rounded-[20px] lg:py-5">
          <p class=" z-50  font-montserrat text-2xl font-bold text-white lg:text-3xl">$ {{ getRewardValue(rank - 1) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    top3: {
      type: Array,
      required: true,
    },
    rank: {
      type: String,
      required: true,
    },
    imageSrc: {
      type: String,
      required: true,
    },
    imageAlt: {
      type: String,
      default: 'Leaderboard banner',
    },
    
  },
  methods: {
    maskString(string) {
      if (string) {
        const firstHalf = Math.ceil(Number(string.length / 2));
        const secondHalf = string.length - firstHalf;

        return string.slice(0, firstHalf) + '*'.repeat(secondHalf);
      }

      return string;
    },
    getRewardValue(rank) {
      const prizes = JSON.parse(process.env.VUE_APP_CURRENT_PRIZES);
      return prizes[rank];
    },
  },
};
</script>
