<template>
  <section id="challenges" class="relative z-20 ">
    <div class="relative px-4 sm:px-8 lg:px-16 py-16 sm:max-w-4xl lg:max-w-6xl 2xl:max-w-7xl mx-auto overflow-hidden">
      <!-- Header Section -->
      <header class="flex flex-col items-center text-center gap-4">
        <img v-motion-pop-visible class="max-w-[300px] object-contain" src="@/assets/hero-twinslots.png"
          alt="Twinslots Logo" />
        <h2 v-motion-pop-visible class="font-bold text-white text-5xl">CHALLENGES</h2>
        <p class="text-white text-lg sm:text-xl lg:text-2xl font-bebas leading-relaxed">
          PLAY USING CODE
          <span class="text-[#FFC125] text-3xl">TWIN</span>
          AND UNLOCK THRILLING REWARDS WITH EXCLUSIVE CHALLENGES—ONLY ON ROOBET!
        </p>
      </header>

      <!-- Challenges Section -->
      <div
        class="flex flex-col md:flex-row flex-wrap gap-6 md:gap-8 lg:gap-12 xl:gap-16 mt-12 justify-center items-center">
        <!-- Challenge Item 1 -->
        <a href="https://roobet.com/game/hacksaw:1067" target="_blank" class="block" aria-label="Challenge 1">
          <img v-motion-pop-visible src="@/assets/challenge-1.png" alt="Challenge 1 - Hacksaw Game"
            class="w-full md:w-64 lg:w-80 h-auto object-contain" />
        </a>

        <!-- Challenge Item 2 -->
        <a href="https://roobet.com/game/pragmatic:vs20sugarrushx" target="_blank" class="block"
          aria-label="Challenge 2">
          <img v-motion-pop-visible src="@/assets/challenge-2.png" alt="Challenge 2 - Sugar Rush Game"
            class="w-full md:w-64 lg:w-80 h-auto object-contain" />
        </a>

        <!-- Challenge Item 3 -->
        <a href="https://roobet.com/game/nolimit:SkateOrDie" target="_blank" class="block" aria-label="Challenge 3">
          <img v-motion-pop-visible src="@/assets/challenge-3.png" alt="Challenge 3 - Skate or Die Game"
            class="w-full md:w-64 lg:w-80 h-auto object-contain" />
        </a>

        <!-- Challenge Item 4 -->
        <a href="https://roobet.com/game/hacksaw:1396" target="_blank" class="block" aria-label="Challenge 4">
          <img v-motion-pop-visible src="@/assets/challenge-4.png" alt="Challenge 4 - Xmas Drop"
            class="w-full md:w-64 lg:w-80 h-auto object-contain" />
        </a>

        <!-- Challenge Item 5 -->
        <a href="https://roobet.com/game/hacksaw:1608" target="_blank" class="block" aria-label="Challenge 5">
          <img v-motion-pop-visible src="@/assets/challenge-5.png" alt="Challenge 5 - Donut Division"
            class="w-full md:w-64 lg:w-80 h-auto object-contain" />
        </a>
      </div>
    </div>
  </section>
</template>
