<template>
  <section id="promotions" class="relative z-10 py-24">
    <div class="relative mx-auto px-4 sm:max-w-4xl lg:max-w-6xl 2xl:max-w-7xl">
      <div class="flex flex-col items-center justify-center gap-2">
        <img
          v-motion-pop-visible
          class="max-w-[300px] object-contain"
          src="@/assets/hero-twinslots.png"
          alt="Twinslots word image"
        />
        <h2 v-motion-pop-visible class="mb-6 text-5xl font-normal text-white">PROMOTIONS</h2>
      </div>

      <!-- Promo Card -->
      <div
        class="relative z-20 min-w-[300px] overflow-hidden rounded-[50px] border-[6px] border-[#FFC125]/80 px-8 pt-4 pb-8 text-center shadow-[0_0_15px_5px_#FFC125]"
      >
        <img
          class="absolute inset-0 z-0 h-full w-full object-cover sm:hidden"
          src="@/assets/promo-card-mobile-bg.png"
          alt="promo mobile background"
        />
        <img
          class="absolute inset-0 z-0 hidden h-full w-full object-cover sm:block"
          src="@/assets/promo-card-bg.png"
          alt="promo background"
        />

        <!-- Content -->
        <div class="relative z-10 flex flex-col">
          <div class="flex w-full justify-between">
            <div
              v-motion-pop-visible
              class="mt-2 flex w-full flex-col items-center justify-center text-center leading-[100px] drop-shadow-[0px_10px_20px_#00F0FF4D] md:w-[400px] md:justify-start md:leading-[140px]"
            >
              <h2 class="text-[60px] text-white md:text-[80px]">USE CODE</h2>
              <h1 class="text-[160px] text-[#FFC125] md:text-[220px]">TWIN</h1>
              <!-- Mobile Button -->
              <a v-motion-pop-visible href="https://roobet.com/?ref=twin" class="mt-8 flex h-[70px] w-full md:hidden">
                <img
                  :src="require('@/assets/hero-button-mobile.png')"
                  alt="Register on Roobet.com button background"
                  class="absolute z-0 h-full w-full rounded-full object-contain transition-opacity duration-300"
                />
              </a>
            </div>
            <div class="flex flex-col justify-center pt-8 lg:flex-row lg:justify-between">
              <!-- Promo Call-to-Action -->
              <div class="relative ml-auto hidden w-[320px] flex-col items-center justify-end md:flex">
                <div v-motion-pop-visible class="flex flex-col items-center justify-center gap-4">
                  <p class="text-4xl text-white">SUPPORT US ON</p>
                  <img class="h-[60px]" src="@/assets/promo-roobet.png" alt="roobet" />
                </div>

                <button
                  v-motion-pop-visible
                  @mouseover="hoveredButton1 = true"
                  @mouseleave="hoveredButton1 = false"
                  class="relative mt-12 h-[82px] w-full max-w-[320px] overflow-hidden rounded-3xl font-bold transition-transform duration-300 hover:scale-105 hover:shadow-lg"
                >
                  <img
                    :src="
                      hoveredButton1
                        ? require('@/assets/hero-hover-cta-1.png')
                        : require('@/assets/hero-idle-cta-1.png')
                    "
                    alt="Register on Roobet.com button background"
                    class="absolute inset-0 z-0 h-full w-full object-cover transition-opacity duration-300"
                  />
                  <a
                    href="https://roobet.com/?ref=twin"
                    class="relative z-10 text-center font-lato text-lg font-bold text-[#19034C]"
                  >
                    Register on Roobet.com
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div v-motion-pop-visible class="mt-8 flex-grow">
            <p class="text-center text-[17px] text-white md:text-[18.5px] 2xl:text-[20px]">
              At the end of each leaderboard, the top 13 players will automatically qualify for an exclusive raffle,
              giving them a chance to win additional prizes! 🏆
            </p>
          </div>
        </div>
      </div>
      <!-- Roobet Rules -->
      <br />
      <br />
      <p class="font-bebas text-2xl text-white">
        Your wagers on Roobet will count towards the leaderboard at the following weights based on the games you are
        playing. This helps prevent leaderboard abuse:
        <br />
        <br />
        *Games with an RTP under 96% will contribute 100% of the amount wagered to the leaderboard.
        <br />
        *Games with an RTP of 97% will contribute 50% of the amount wagered to the leaderboard.
        <br />
        *Games with an RTP of 98- 99% and above will contribute 10% of the amount wagered to the leaderboard.
        <br />
        *Only house games and slots count towards leaderboard.
      </p>
    </div>

    <img
      v-motion-pop-visible
      class="absolute -right-6 bottom-0 z-0 w-[13%]"
      src="@/assets/promo-planet.png"
      alt="rocket"
    />
  </section>
</template>

<script>
import { ref } from 'vue';

export default {
  data() {
    return {
      promoList: [
        { wager: '$2,500', img: require('@/assets/promo-earn-10.png') },
        { wager: '$10,000', img: require('@/assets/promo-earn-25.png') },
        { wager: '$25,000', img: require('@/assets/promo-earn-50.png') },
        { wager: '$50,000', img: require('@/assets/promo-earn-100.png') },
        { wager: '$100,000', img: require('@/assets/promo-earn-200.png') },
      ],
      hoveredButton1: ref(false),
    };
  },
};
</script>
