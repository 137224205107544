<template>
  <div>
    <!-- Main section start -->
    <HeroSection />

    <div class="w-full bg-gradient-to-b from-[#01122D] via-[#001B9F] to-[#009BBD]">
      <div class="overflow-hidden bg-[url('@/assets/promo-bg.png')] bg-cover bg-center bg-no-repeat">
        <PromotionsSection />
        <SocMedSection />
      </div>
      <LeaderboardSection />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import HeroSection from '@/sections/HeroSection.vue';
import PromotionsSection from '@/sections/PromotionsSection.vue';
import SocMedSection from '@/sections/SocMedSection.vue';
import LeaderboardSection from '@/sections/LeaderboardSection.vue';

export default defineComponent({
  components: {
    HeroSection,
    PromotionsSection,
    SocMedSection,
    LeaderboardSection,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {},
});
</script>
